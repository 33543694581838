<div id="wrapper">
  <div class="page-wraper">

    <app-topbar></app-topbar>

    <section id="content-wrapper">
      <router-outlet></router-outlet>
    </section>

    <app-sidebar></app-sidebar>

  </div>
</div>
