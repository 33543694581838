export class Url {
  // url = 'http://localhost:3000'
  public static baseUrl: string = 'https://api-apply-trokit.herokuapp.com';

  public static apiUrl: string = `${Url.baseUrl}/api`;
  public static apiImagePath: string = `${Url.baseUrl}/images/`;
  public static apiImageUpload: string = `${Url.baseUrl}/upload`;
  public static apiImageUploadEditor: string = `${Url.baseUrl}/upload/editor`;
  public static apiFilePath: string = `${Url.baseUrl}/file/`;
}
