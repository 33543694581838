import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Subscriber } from "rxjs";
import { Url } from './url';

@Injectable()
export class HttpHelper {

    constructor(private http: Http,private router: Router) {

    }

    postResetPassword(url, param): Observable<any> {
      const bodyString = JSON.stringify(param);
      var headers = this.jwtReset();
      headers.headers.append('Content-Type', 'application/json');
      return this.http.post(Url.apiUrl + url, bodyString, headers)
          .map((response: Response) => response.json())
          .catch(this.handleError.bind(this,this.router));
    }
    jwtReset() {
      if (localStorage.getItem('token') != null) {
          const token = JSON.parse(localStorage.getItem('token'));
          if (token ) {
              const headers = new Headers({ 'Authorization': 'Bearer ' + token, "Language": this.getLang() });
              return new RequestOptions({ headers: headers });
          }
      }
      else {
          return new RequestOptions({ headers: new Headers({ 'Content-Type': 'application/json' })});
      }
    }

    postWithoutToken(url, param): Observable<any> {
      const bodyString = JSON.stringify(param);
      var headers = this.jwtWithoutToken();
      headers.headers.append('Content-Type', 'application/json');
      return this.http.post(Url.apiUrl + url, bodyString, headers)
          .map((response: Response) => response.json())
          .catch(this.handleError.bind(this,this.router));
    }
    jwtWithoutToken() {
      if (localStorage.getItem('token') != null) {
          const token = JSON.parse(localStorage.getItem('token'));
          if (token ) {
              const headers = new Headers({ 'Authorization': 'Bearer ' + token, "Language": this.getLang() });
              return new RequestOptions({ headers: headers });
          }
      }
      else {
          return new RequestOptions({ headers: new Headers({ 'Content-Type': 'application/json', "Language": this.getLang() })});
      }
    }


    post(url, param): Observable<any> {

        const bodyString = JSON.stringify(param);
        var headers = this.jwt();

        headers.headers.append('Content-Type', 'application/json');
        return this.http.post(Url.apiUrl + url, bodyString, headers)
            .map((response: Response) => response.json())
            .catch(this.handleError.bind(this,this.router));

    }

    postOutSite(url, param): Observable<any> {
      const bodyString = JSON.stringify(param);
      var headers = this.jwt();

      headers.headers.append('Content-Type', 'application/json');
      return this.http.post(url, bodyString, headers)
          .map((response: Response) => response.json())
          .catch(this.handleError.bind(this,this.router));
    }


    get(url): Observable<any> {

        var headers = this.jwt();
        return this.http.get(Url.apiUrl + url, headers)
            .map((response: Response) => response.json())
            .catch(this.handleError.bind(this,this.router));

    }

    getLang()
    {
        var lang=localStorage.getItem('lang');
        if(lang == null)
        {
            return 'de';
        }

        return lang;
    }


    jwt() {

        if (localStorage.getItem('currentUser') != null) {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));

            if (currentUser && currentUser.token) {
                const headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token, "Language": this.getLang() });
                return new RequestOptions({ headers: headers });
            }
        }
        else {
          //nese so i logirat e qon veq gjuhen
            const headers = new Headers({  "Language": "en" });
            return new RequestOptions({ headers: headers });
        }
    }

    handleError(router,error: Response | any) {

        if(error.status == 402)
        {
            console.log(router);
            localStorage.clear();

            document.location.href ="/login";
            //router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        }
        else if(error.status == 401)
        {
            console.log(router);
            router.navigate(['/not-access']);
        }
        else if(error.status == 0)
        {
            console.log(router);
            // router.navigate(['/error',{type:0}]);
        }
        else
        {
            // router.navigate(['/error']);
        }

        console.log(error.message || error);
        return new Observable<any>((subscriber: Subscriber<any>) =>
        subscriber.next([])).map(o => o);
    }
}
