
export class Auth {
  id = null;
  name = null;
  email = null;
  password = null;
  role = null;
  attemps = null;
  phone = null;
  address = null;
  city = null;
  state = null;
  lang = null;
  token = null;




  get isLogged(): boolean {
    return this.email != null;
  }

  get isAdmin(): boolean {
    return this.role == 'admin';
  }

  get isCompany(): boolean {
    return this.role == 'company';
  }




  static getDataOfUser(): Auth {
    const currentUser: Auth = JSON.parse(localStorage.getItem('currentUser'));

    const userAuth = new Auth();

    for (var key in currentUser) {
      if (currentUser.hasOwnProperty(key)) {
        userAuth[key] = currentUser[key];
      }
      // console.error(key);
    }

    return userAuth;
  }

}



