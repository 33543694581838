import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UploadService } from '../services/upload.service';
import {EventEmitterService} from '../../../common/page.events';
import { Auth } from 'src/app/common/auth';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: "app-topbar",
    templateUrl: "../views/topbar.component.html",
    styleUrls: ['../styles/topbar.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TopbarComponent implements OnInit {


  constructor(private router: Router,
              private EventEmitterService: EventEmitterService,
              private upload: UploadService,
              private translate: TranslateService) {

  }

  ngOnInit() {

  }



}

