import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class pagesToggleService {
  //Search Toggle
  private _minimazeModule = new Subject();
  minimazeModule = this._minimazeModule.asObservable();

  private _closeModule = new Subject();
  closeModule = this._closeModule.asObservable();

  private _minimazeModuleLead = new Subject();
  minimazeModuleLead = this._minimazeModuleLead.asObservable();

  private _closeModuleLead = new Subject();
  closeModuleLead = this._closeModuleLead.asObservable();


  minModule(param) {
    this._minimazeModule.next(param);
  }

  setCloseModule(param) {
    this._closeModule.next(param);
  }



  minModuleLead(param) {
    this._minimazeModuleLead.next(param);
  }

  setCloseModuleLead(param) {
    this._closeModuleLead.next(param);
  }

}
