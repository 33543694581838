import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './components/sidebar.component';
import { TopbarComponent } from './components/topbar.component';
import { DefaultComponent } from '../pages/default.component';
import { LayoutComponent } from './components/layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { pagesToggleService } from '../pages/dashboard/services/toggler.service';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PositioningService } from 'ngx-bootstrap/positioning';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UploadService } from './services/upload.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { Auth } from 'src/app/common/auth';
import { WindowModule } from "@progress/kendo-angular-dialog";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from "@progress/kendo-angular-inputs";


@NgModule({
    declarations: [
        SidebarComponent,
        TopbarComponent,
        DefaultComponent,
        LayoutComponent,
    ],
    exports: [

        SidebarComponent,
        TopbarComponent,
        DefaultComponent,
        LayoutComponent,
        RouterModule
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DropDownsModule,
        ButtonsModule,
        TooltipModule,
        WindowModule,
        DialogsModule,
        InputsModule
    ],
    providers: [
      pagesToggleService,
      BsModalService,
      ComponentLoaderFactory,
      PositioningService,
      UploadService,
      Auth
    ]
})
export class LayoutModule {
}
