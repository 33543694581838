

export class AuthentificationService {

    constructor() {

    }

    IsAuthenticated():boolean
    {
      //
        return true;
    }
}
