import { AdminGuard } from './../common/admin.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { CmsComponent } from './cms.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../common/auth.guard';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';


const routes: Routes = [
  {
    'path': '',
    'component': CmsComponent,
    'children': [
      {
        'path': 'apply',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        'path': '',
        'redirectTo': 'apply',
        'pathMatch': 'full'
      },
    ]
  },
  {
    'path': '**',
    'redirectTo': '404',
    'pathMatch': 'full'
  }
];

@NgModule({
    imports: [
      RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class CmsRoutingModule { }
