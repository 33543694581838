import { FormGroup } from '@angular/forms';

export class FormHelper {

   public static displayErrors( form:FormGroup)
   {
        Object.keys(form.controls).map((controlName) => {
            form.get(controlName).markAsTouched({onlySelf:true})
            form.get(controlName).markAsDirty({onlySelf:true})
        });
   }
}
