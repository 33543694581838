import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
    selector: "cms",
    templateUrl: "./cms.component.html",
})
export class CmsComponent implements OnInit {

    constructor(private _router: Router) {

    }

    ngOnInit() {

    }
}
