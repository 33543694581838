import { Component, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
    selector: "app-layout",
    templateUrl: "../views/layout.component.html",
    styleUrls: ['../styles/layout.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {


    }
}
