import { AdminGuard } from './common/admin.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule,  HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClientJsonpModule, HttpClient  } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CmsComponent } from './cms/cms.component';
import { LayoutModule } from './cms/layouts/layout.module';
import { CmsRoutingModule } from './cms/cms-routing.module';

import { AuthGuard } from './common/auth.guard';
import { HttpHelper } from './common/http-helper';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { pagesToggleService } from './common/pagesToggleService';

import { TranslatePipe } from '@ngx-translate/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { EventEmitterService } from './common/page.events';
import { RecaptchaModule } from "ng-recaptcha";




const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class AppHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      'pinch': { enable: false },
      'rotate': { enable: false }
  }
}



@NgModule({
  declarations: [
    AppComponent,
    CmsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LayoutModule,
    Ng2SearchPipeModule,
    RouterModule,
    CmsRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    HttpModule,
    RecaptchaModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    ButtonsModule,
    DialogsModule,
    TooltipModule
  ],
  providers: [
    AuthGuard,
    AdminGuard,
    pagesToggleService,
    EventEmitterService,
    HttpHelper,
    DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    },
    { provide: LOCALE_ID, useValue: 'en' },
  ],
  exports: [TranslateModule, TranslatePipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
