import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PagesEventService {

    private _changeTitle = <Subject<any>>new Subject()
    Title = this._changeTitle.asObservable();

    private _changeBreadcrumb = <Subject<any>>new Subject()
    Breadcrumb = this._changeBreadcrumb.asObservable();

    private _onSaving = <Subject<any>>new Subject()
    OnSaving = this._onSaving.asObservable();

    setTitle(param: any) {
        this._changeTitle.next(param);
    }

    setBreadcrumb(param: any) {
        this._changeBreadcrumb.next(param);
    }

    onSaving(param: any) {
        this._onSaving.next(param);
    }


}
