
export class RequestResponse {
    Status=null;
    Error=null;

    IsStatusOk()
    {
        return this.Status === '200';
    }
}
