<footer>
  <div class="page-container">
    <div class="widgets">
      <div class="widget widget--logo">
        <div class="logo">
          <a style="opacity: 1;"><img src="../../../../assets/images/footer-logo.svg" alt=""></a>
        </div>
      </div>
      <div class="widget">
        <h4>Switzerland</h4>
        <p>Nidaugasse 22, 2502 Biel, <br>Switzerland</p>
        <br>
        <a>+41 32 510 77 60</a>
        <a>info@trokit.ch</a>
      </div>
      <div class="widget">
        <h4>Kosovo</h4>
        <p>Str. Sadik Zeneli BB 3/1-C2, <br>nr.3, Prishtina 10000</p>
        <br>
        <a>+383 38 552 331</a>
        <a>info@trokit.com</a>
      </div>
    </div>
    <span class="divider"></span>
    <p class="copyright">© 2022 Trokit Agency Creative</p>
  </div>
</footer>
