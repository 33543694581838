import { Injectable } from '@angular/core';
// import { NotificationService as KendoNotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
  ) { }

  showSuccess(message: string): void {
    // this.kendoNotification.show({
    //   content: message,
    //   cssClass: 'button-notification',
    //   animation: { type: 'fade', duration: 400 },
    //   position: { horizontal: 'center', vertical: 'top' },
    //   type: { style: 'success', icon: true },
    //   hideAfter: 4000
    // });
  }

  showError(message: string): void {
    // this.kendoNotification.show({
    //   content: message,
    //   cssClass: 'button-notification',
    //   animation: { type: 'fade', duration: 400 },
    //   position: { horizontal: 'center', vertical: 'bottom' },
    //   type: { style: 'error', icon: true },
    //   closable: true
    // });
  }

  showErrorHide(message: string): void {

  }

  showInfo(message: string): void {

  }
}
