import { formatDate } from '@angular/common';

export class Utilities {

  public static parseToDate(date: string): Date {
    if (date == null)
      return null;

    if (typeof date == "object")
      return date;

    const locale = 'en-US';
    return new Date(formatDate(date, 'yyyy-MM-dd H:mm:ss', locale))
  }

  public static getFormatedObject(model)
  {

    for (var attr in model) {

       if(model[attr] != null && typeof model[attr].getMonth !== 'function' && typeof model[attr] == "object")
       {
        model[attr]= model[attr]?.id;
       }
       else if(model[attr] != null && typeof model[attr].getMonth === 'function' && typeof model[attr] == "object")
       {
        model[attr]= Utilities.getFormatedDate(model[attr]);
       }
    }

    return model;
  }

  public static parseToTime(date: string): Date {
    if (date == null)
      return null;

    if (typeof date == "object")
      return date;

    const locale = 'en-US';
    return new Date(formatDate(date, 'yyyy-MM-dd H:mm:ss', locale))

  }

  public static getFormatedDate(date: Date): string {
    if (date == null)
      return null;

    const locale = 'en-US';
    return formatDate(date, 'yyyy-MM-dd', locale)
  }

  public static getFormatedDateTime(date: Date): string {
    if (date == null)
      return null;

    const locale = 'en-US';
    return formatDate(date, 'yyyy-MM-dd HH:mm:ss', locale)
  }

  public static FormateDateTime(date: Date): string {
    if (date == null)
      return null;

    const locale = 'en-US';
    return formatDate(date, 'dd.MM.yyyy HH:mm:ss', locale)
  }

  public static  parseToDateObject(data,dateColumns) {

    for (let index = 0; index < data.length; index++) {
      let employee = data[index] as any;

      dateColumns.forEach(column => {
        employee[column.field] = Utilities.parseToDate(employee[column.field]);
      })
    }
  }

  public static async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }
}
